import React from 'react'
import './SalonServicesTabs.css';
import { useState, useEffect } from 'react'
import './SalonServiceTab/SalonServiceTab.css'
import {db} from '../../firebase-config';
import {collection, getDocs} from 'firebase/firestore';
import SalonServiceTabImproved from './SalonServiceTabImproved/SalonServiceTabImproved';

const SalonServicesTabs = () => {
    const [isActiveTab1, setActiveTab1] = useState(true);
    const [isActiveTab2, setActiveTab2] = useState(false);
    const [isActiveTab3, setActiveTab3] = useState(false);

    const [hairListQuery, setHairListQuery] = useState([]);
    const [colorQuery, setColorQuery] = useState([]);
    const [otherQuery, setOtherQuery] = useState([]);
    const infoCollectionRef = collection(db, 'hair-rituals');
    const infoCollectionRef2 = collection(db, 'customized-color');
    const infoCollectionRef3 = collection(db, 'face-rituals');
    
    useEffect(() => {
        const getInfo = async() => {
          const data = await getDocs(infoCollectionRef);
          setHairListQuery(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }
        getInfo();
        
      }, []);

    useEffect(() => {
        const getInfo2 = async() => {
          const data = await getDocs(infoCollectionRef2);
          setColorQuery(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }
        getInfo2();
        
      }, []);
      useEffect(() => {
        const getInfo3 = async() => {
          const data = await getDocs(infoCollectionRef3);
          setOtherQuery(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }
        getInfo3();
        
      }, []);



    const DisableClasses = () => {
        setActiveTab1(false);
        setActiveTab2(false);
        setActiveTab3(false);
    };



    function EnableClass(givenClass){
        DisableClasses();
        if(givenClass === '1') {
            setActiveTab1(true);
        }
        if(givenClass === '2') {
            setActiveTab2(true);
        }
        if(givenClass === '3') {
            setActiveTab3(true);
        }
    }

    

  return (
    <div className='salon__service__tab__wrapper'>
        <ul className='tab__tabs'>
            <li className={isActiveTab1 ? "salon_tab active" : "salon_tab"} onClick={() =>EnableClass('1')}>Hair Rituals</li>

            <li className={isActiveTab2 ? "salon_tab active" : "salon_tab"} onClick={() =>EnableClass('2')}>Customized Color</li>

            <li className={isActiveTab3 ? "salon_tab active" : "salon_tab"} onClick={() =>EnableClass('3')}>Face Rituals</li>
        </ul>
        <div className='tab__content'>
            <ul className={isActiveTab1 ? "salon_content__tab active" : "salon_content__tab"}>
            {hairListQuery.map((obj, i) => {
                return(<li key={i}><SalonServiceTabImproved title={obj.title} information={obj.desc}  image={obj.image} price={obj.price} subDescription={obj.subDescription}/></li>)})
            }
            </ul>
            <ul className={isActiveTab2 ? "salon_content__tab active" : "salon_content__tab"}>
            {colorQuery.map((obj, i) => {
                return(<li key={i}><SalonServiceTabImproved title={obj.title} information={obj.desc}  image={obj.image} price={obj.price} subDescription={obj.subDescription}/></li>)})
            }
            </ul>
            <ul className={isActiveTab3 ? "salon_content__tab active" : "salon_content__tab"}>
            {otherQuery.map((obj, i) => {
                return(<li key={i}><SalonServiceTabImproved title={obj.title} information={obj.desc}  image={obj.image} price={obj.price} subDescription={obj.subDescription}/></li>)})
            }
            </ul>
        </div>
</div>
  )
}

export default SalonServicesTabs