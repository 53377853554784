import React, {useState, useEffect} from 'react';

import ElectricFoxNav from '../../components/navbar/ElectricFoxNav/ElectricFoxNav';
import ElectricFoxLoader from '../../components/ElectricFoxLoader/ElectricFoxLoader';
import {Images} from '../../constants';
import './Home.css';
import SwiperPage from '../../components/SwiperPage/SwiperPage'
import images from '../../constants/images';
import { Link } from 'react-router-dom';

const Home = () => {
  document.title = "Electric Fox at Sundance"
  return (
    <div className='homePage'>
      <ElectricFoxLoader />
      <ElectricFoxNav />
      <div className='homepagehero'>
        <div className='homepageheroleft'>
          {/* <img src={images.BWBG} alt='decorative vector background mountain' aria-hidden="true" ></img > */}
          <div className='decorativeStatic'></div>
          <div className='homepageheroleftcontent'>
            <h1><span className='purple'>Sally Marchand Collins</span> Founder of <span className='orange'>Electric Fox at Sundance</span> and <span className='green'>Sundance MTB Skills Clinic</span></h1>
            <p>Invites you to experience her two passions. She is an award winning hairstylist and owner of one of the top 10 "Must Attend" Mountain Bike Camps in the US. </p>
            <div className='homebuttoncontainer'>
            <Link to='/biking' className='homepagebutton homepagebuttonmtb'>Sundance MTB Skills Clinic</Link>
            <Link to='/salon' className='homepagebutton homepagebuttonsalon'>Electric Fox AVEDA Concept Salon</Link>
            </div>
          </div>
        </div>
        <div className='homepageheroright'>
          <div className='decorativeTint'></div>
          <img src={images.mtbimage10} alt="decorative biking image"/>
        </div>
        <div className='mainLogoHome'> <Link to='/biking'><img src={images.logoLong3} alt='Electric Fox at Sundance Logo' ></img></Link> <Link to='/biking'><img src={images.mainLogoMTB} alt='Sundance MTB Skill Clinic Logo' ></img></Link></div>
      </div>
      <div className='home-page-wrapper'>
      <section className='homepage__about__intro'>
            <h1>Sally is changing the game</h1>
            <p>Her Electric Fox at Sundance AVEDA Concept Salon offers designer hair cuts, advanced color techniques, cosmetic makeovers, and eyebrow design. We are located downtown at the heart of the charming small-town Zionsville.</p>
            <p>Her Sundance MTB Skills Clinic offers stellar coaching, building better bikers one skill at a time from the beginner through the advanced mountain biker.</p>
            <div className='homebuttoncontainer'>
            <Link to='/biking' className='homepagebutton homepagebuttonmtb'>Learn to ride!</Link>
            <Link to='/salon' className='homepagebutton homepagebuttonsalon'>Get the perfect cut!</Link>
            </div>
            <div className='homepageImageContainer'>
              <img src={images.trey} className='homepageImage1' alt='boy mountain biking' loading="lazy"></img>
              <img src={images.thumbnail} className='homepageImage2' alt='salon lounge' loading="lazy"></img>
              <img src={images.mtbimage10} className='homepageImage3' alt='mountain biking group photo' loading="lazy"></img>
            </div>
          </section>
          
      </div>
    </div>
  )
}

export default Home