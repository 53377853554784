import React from 'react'
import { useState } from 'react'

const SalonServiceTabImproved = (props) => {
    const [isActive, setActive] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive); 
   };
  return (
    <div className='service__tab__salon__item'>
    <div className='tab__clickable__title' onClick={()=>ToggleClass()}>{props.title} {props.price}<span className={isActive ? "active__salon__arrow active" : "active__salon__arrow"}>➤</span></div>

    <div className='tabIndividualContent'>
      <img src={props.image} className={isActive ? "tab__content__hidden active" : "tab__content__hidden"}></img>
      <div className={isActive ? "tab__content__hidden active" : "tab__content__hidden"}>{props.information }<br></br><p class='service-sub-description'>{props.subDescription}</p></div>
    
    </div>

    </div>
  )
}

export default SalonServiceTabImproved